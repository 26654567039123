import { DOCUMENT, JsonPipe, NgIf } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { interval, map, Observable, Subscription, take } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-login-callback',
  standalone: true,
  imports: [JsonPipe, NgIf],
  templateUrl: './login-callback.component.html',
  styleUrl: './login-callback.component.scss',
})
export class LoginCallbackComponent {
  private readonly authService = inject(AuthService);
  private readonly oauthService = inject(OAuthService);

  title = 'Chemical Common Settings';
  countDown: number = 3;
  private subscription: Subscription | undefined;

  constructor(@Inject(DOCUMENT) private document: Document, public route: ActivatedRoute, public router: Router) {}

  ngOnInit(): void {
    const token = this.oauthService.getIdToken();

    this.subscription = interval(1000)
      .pipe(take(3))
      .subscribe({
        next: () => {
          this.countDown--;
        },
        complete: () => {
          console.log(token, 'navigate to previous page');
          // if (token) {
          //   this.navigateToPreviousPage();
          // } else {
            this.router.navigate(['/']);
          // }
        },
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  navigateToPreviousPage() {
    window.history.back();
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  get userName(): string {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) return '';
    return 'given_name';
  }

  get idToken(): string {
    return this.oauthService.getIdToken();
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }
}
