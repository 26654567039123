<div class="flex">
  <div class="flex flex-1 items-center justify-center bg-background mt-10">
    <div class="max-w-md overflow-y-auto px-4 p-5 shadow-md">
      <!-- logo -->
      <div class="flex items-center justify-center">
        <b class="text-lg pl-3 font-bold text-chemPrimary"> Redirecting </b>
      </div>
      <!-- Route -->
      <p class="mt-5">Please wait a second...<strong class="text-lg">{{countDown}}</strong>. You are being redirected.
      </p>
    </div>
  </div>
</div>