import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { finalize, tap } from 'rxjs';
import { LoadingService } from '../services/loading.service';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  // const authService = inject(AuthService);
  const oauthService = inject(OAuthService);
  const loadingService = inject(LoadingService);
  const token = oauthService.getAccessToken();
  const allowedOrigins = ['/api'];
  let totalRequests = 0;

  if (token && !!allowedOrigins.find((origin) => req.url.includes(origin))) {
    if (!req.headers.get('ignoreLoading')) {
      totalRequests++;
      loadingService.setLoading(true);
    }

    req = req.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  return next(req).pipe(
    tap(
      (event) => {
        if (event instanceof HttpResponse && !req.headers.get('ignoreLoading')) {
          totalRequests--;
          if (totalRequests === 0) {
            loadingService.setLoading(false);
          }
        }
      },
      (error) => {
        if (!req.headers.get('ignoreLoading')) {
          totalRequests--;
          if (totalRequests === 0) {
            loadingService.setLoading(false);
          }
        }
      },
    ),
    finalize(() => {
      if (!req.headers.get('ignoreLoading')) {
        totalRequests--;
        if (totalRequests === 0) {
          loadingService.setLoading(false);
        }
      }
    }),
  );
};
