import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, retry, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService {
  // protected httpOptions = {
  //   headers: new HttpHeaders().append('Content-Type', 'application/json'),
  // };

  constructor(protected http: HttpClient, private toastr: ToastrService) {}

  private removeUndefinedParams(params: { [key: string]: any }): { [key: string]: any } {
    return Object.entries(params).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [key: string]: any });
  }

  protected get<T = any>(url: string, params = {}, ignoreLoading: boolean = false): Observable<T> {
    const cleanParams = this.removeUndefinedParams(params);
    return this.http
      .get<T>(`${environment.apiUrl}${url}`, {
        ...this.getHttpOptions(ignoreLoading),
        params: cleanParams,
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  protected post<T = any>(url: string, data: any, ignoreLoading: boolean = false): Observable<T> {
    return this.http
      .post<T>(`${environment.apiUrl}${url}`, data, {
        ...this.getHttpOptions(ignoreLoading),
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  protected put<T = any>(url: string, data: any, ignoreLoading: boolean = false): Observable<T> {
    return this.http
      .put<T>(`${environment.apiUrl}${url}`, data, {
        ...this.getHttpOptions(ignoreLoading),
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  protected delete<T = any>(url: string, params = {}, ignoreLoading: boolean = false): Observable<T> {
    return this.http
      .delete<T>(`${environment.apiUrl}${url}`, {
        ...this.getHttpOptions(ignoreLoading),
        params,
      })
      .pipe(retry(1), catchError(this.handleError.bind(this)));
  }

  protected getHttpOptions(ignoreLoading: boolean = false): { headers: HttpHeaders } {
    let headers = new HttpHeaders().append('Content-Type', 'application/json');

    if (ignoreLoading) {
      headers = headers.append('ignoreLoading', 'true');
    }

    return { headers };
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    // Handle client-side or network errors
    if (+error.status < 500) {
      // Check if the error is a 401 Unauthorized error and the request is to the sdsmanager API
      if (+error.status == 401) {
        // Refresh the token and retry the request
      }
      console.error(`${error}`);
    } else {
      console.error(`${error}`);
    }
    this.toastr.error(error.message, 'Error');
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
