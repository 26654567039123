import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSignal = signal(false);

  setLoading(isLoading: boolean) {
    this.loadingSignal.set(isLoading);
  }

  get loading() {
    return this.loadingSignal.asReadonly();
  }
}
