import { Component, inject } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { Router, RouterOutlet } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { ResponsiveHelperComponent } from './shared/components/responsive-helper/responsive-helper.component';
import { environment } from 'src/environments/environment';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './core/auth/auth.config';
import { filter } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { LoadingService } from './core/services/loading.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgClass, RouterOutlet, ResponsiveHelperComponent, NgIf, LoadingComponent],
})
export class AppComponent {
  protected readonly environment = environment;

  private readonly oauthService = inject(OAuthService);
  private readonly translate = inject(TranslateService);

  constructor(private loadingService: LoadingService, private http: HttpClient) {
    const authConfigLocal: AuthConfig = {
      issuer: environment.oidcIssuer,
      redirectUri: window.location.origin + environment.oidcRedirectUri,
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true,
      clientId: environment.oidcClientId,
      responseType: 'code',
      postLogoutRedirectUri: window.location.origin,
      scope: 'offline_access',
      showDebugInformation: false,
      useSilentRefresh: false,
    };


    this.oauthService.configure(authConfigLocal);
    this.oauthService.loadDiscoveryDocumentAndLogin();

    this.oauthService.setupAutomaticSilentRefresh();

    // Automatically load user profile
    this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe((_) => {
      console.log('loadUserProfile');
      this.oauthService.loadUserProfile();
    });
  }

  ngOnInit(): void {
    // this language will be used as a fallback when a translation isn't found in the current language
    const lang = localStorage.getItem('languageCulture') || 'en';
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }
}
