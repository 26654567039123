import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';
import { EmployeeModel } from '../../admin/pages/user/models/user-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private readonly oauthService = inject(OAuthService);

  login() {
    this.oauthService.initImplicitFlow();
  }

  logout() {
    this.oauthService.revokeTokenAndLogout();
  }

  refresh() {
    this.oauthService.refreshToken();
  }

  getUserProfile(getForSetup = false): Observable<EmployeeModel> {
    return this.get('admin/employeeInfo', { ignoreIdNumber: false });
  }
}
