import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { authConfig } from './core/auth/auth.config';
import { provideTranslation } from './core/config/translate-loader.config';
import { jwtInterceptor } from './core/interceptor/jwt.interceptor';
import { providerEnvironment } from 'src/environments/environment.provider';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { provideToastr } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      MatNativeDateModule,
      TranslateModule.forRoot(provideTranslation()),
    ),
    provideAnimations(),
    provideToastr({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([jwtInterceptor])),
    provideOAuthClient(),
    providerEnvironment(),
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
};
