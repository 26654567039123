import { Environment } from './environment.interface';

export class EnvironmentLoader {
  private static env: Environment;

  public static get environment(): Environment {
    return EnvironmentLoader.env;
  }

  public static async loadEnvironment(): Promise<void> {
    const response = await fetch('./assets/environment.json');
    try {
      EnvironmentLoader.env = await response.json();
      const moduleConfig = await fetch(`${EnvironmentLoader.env.apiUrl}common/moduleConfig`);
      EnvironmentLoader.env.server = await moduleConfig.json();

    } catch (e) {
      console.log('Could not load config, oh no!');
    }
  }
}
